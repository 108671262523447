import alterpressApi from './base';

import { IClientLegalConsentTemplate } from '../../models/client-legal-consent-template.model';
import { IClientLegalConsentTemplateValues } from '../../formik/client-legal-consent-template.form';

export const clientLegalConsentTemplateApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['CLIENT_LEGAL_CONSENT_TEMPLATE'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getAllClientLegalConsentTemplates: builder.query<IClientLegalConsentTemplate[], void>({
                query: () => `client-legal-consent-templates/all`,
                providesTags: ['CLIENT_LEGAL_CONSENT_TEMPLATE'],
            }),
            getClientLegalConsentTemplate: builder.query<
                IClientLegalConsentTemplate,
                number | string | undefined | null
            >({
                query: (templateId) => `client-legal-consent-templates/${templateId}`,
                providesTags: ['CLIENT_LEGAL_CONSENT_TEMPLATE'],
            }),
            createClientLegalConsentTemplate: builder.mutation<
                IClientLegalConsentTemplate,
                IClientLegalConsentTemplateValues
            >({
                query: (data) => ({
                    url: `client-legal-consent-templates`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_LEGAL_CONSENT_TEMPLATE'],
            }),
            updateClientLegalConsentTemplate: builder.mutation<
                IClientLegalConsentTemplate,
                { templateId: number | string; data: IClientLegalConsentTemplateValues }
            >({
                query: ({ data, templateId }) => ({
                    url: `client-legal-consent-templates/${templateId}`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_LEGAL_CONSENT_TEMPLATE'],
            }),
            deleteClientLegalConsentTemplate: builder.mutation<void, number | string>({
                query: (templateId) => ({
                    url: `client-legal-consent-templates/${templateId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['CLIENT_LEGAL_CONSENT_TEMPLATE'],
            }),
        }),
    });

export const {
    useGetAllClientLegalConsentTemplatesQuery,
    useGetClientLegalConsentTemplateQuery,
    useCreateClientLegalConsentTemplateMutation,
    useUpdateClientLegalConsentTemplateMutation,
    useDeleteClientLegalConsentTemplateMutation,
} = clientLegalConsentTemplateApi;
