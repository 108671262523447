import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import {
    sectionBox,
    grid,
    topWrapper,
    container,
    radiusBox,
    divider,
    templateTitle,
    wrapper,
    buttons,
    opacity,
} from './client-legal-consent-template-preview.module.scss';
import { ISection } from '../../models/section.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { useClientLegalConsentTemplate } from '../../hooks/use-client-legal-consent-template';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import useTranslations from '../../hooks/use-translations';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import { useModal } from '../../hooks/use-modal';
import { useBackLink } from '../../hooks/use-back-link';

import Section from '../hoc/section';
import DashboardHeader from '../molecules/dashboard-header';
import RadiusBox from '../hoc/radius-box';
import DetailsColumn from '../atoms/details-column';
import Button from '../atoms/button';
import IconFactory from '../hoc/icon-factory';
import NoPermissionInfo from '../organisms/no-permission-info';
import FetchStatusInfo from '../molecules/fetch-status-info';

interface IClientLegalConsentTemplatePreviewProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

interface IClientLegalConsentTemplatePreviewQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const ClientLegalConsentTemplatePreview: React.FC<IClientLegalConsentTemplatePreviewProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const { style, sectionId, css } = section;
    const { addModal } = useModal();
    const t = useTranslations('ClientLegalConsentTemplatePreview');
    const templateId = getUrlParamValue('id');
    const template = useClientLegalConsentTemplate({ queries: ['single'], templateId });

    const { allPage } = useStaticQuery<IClientLegalConsentTemplatePreviewQueryResult>(query);
    const fallbackPathname = usePagePathname(allPage, 'client-legal-consent-templates');
    const clientLegalConsentTemplateCreatePage = usePagePathname(
        allPage,
        'client-consent-template-form'
    );
    const prevPathname = useBackLink(fallbackPathname);

    const handleTemplateDelete = () => {
        if (!templateId) return;
        addModal({
            modalKey: 'delete-confirm-modal',
            modalProps: { onConfirm: handleTemplateDeleteConfirmation(templateId) },
        });
    };

    const handleTemplateDeleteConfirmation = (templateId: number | string) => {
        return async () => {
            try {
                await template.delete.fetch(templateId).unwrap();
                navigate(prevPathname);
            } catch {
                addModal({ modalKey: 'delete-error-modal' });
            }
        };
    };

    if (template.single.isUnauthorized) {
        return <NoPermissionInfo reason="role" isFullHeight={true} />;
    }

    return (
        <Section
            className={`${sectionBox} ${className} ${template.delete.isLoading ? opacity : ''}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                {!template.single.data &&
                    (template.single.isLoading || template.single.isError) && (
                        <FetchStatusInfo
                            isLoading={template.single.isLoading}
                            isError={template.single.isError}
                            error={t.fetchError}
                        />
                    )}
                {template.single.data && (
                    <>
                        <div className={topWrapper}>
                            <DashboardHeader hint={t.hint} TitleTag={TitleTag}>
                                {t.title}
                            </DashboardHeader>
                            <div className={buttons}>
                                <Button
                                    stylePreset="danger"
                                    size="medium"
                                    shape="circle"
                                    onClick={handleTemplateDelete}
                                >
                                    <IconFactory icon="trash" />
                                </Button>
                                <Button
                                    stylePreset="secondary"
                                    size="medium"
                                    shape="circle"
                                    as="link"
                                    to={`${clientLegalConsentTemplateCreatePage}?id=${template.single.data.templateId}`}
                                >
                                    <IconFactory icon="edit" />
                                </Button>
                            </div>
                        </div>
                        <RadiusBox className={radiusBox}>
                            <p className={templateTitle}>
                                {t.id}: {template.single.data.templateId}
                            </p>
                            <div className={divider} />
                            <div className={wrapper}>
                                <DetailsColumn
                                    label={t.name}
                                    values={[`${template.single.data.name}`]}
                                />
                                <DetailsColumn
                                    label={t.language}
                                    values={[`${template.single.data.language.toUpperCase()}`]}
                                />
                                <DetailsColumn
                                    label={t.createdAt}
                                    values={[
                                        `${getDateFromUnixTimestamp(
                                            template.single.data.createdAt,
                                            'dash'
                                        )}`,
                                    ]}
                                />
                                <DetailsColumn
                                    label={t.author}
                                    values={[`${template.single.data.author}`]}
                                />
                            </div>
                            <DetailsColumn
                                label={t.content}
                                values={[`${template.single.data.content}`]}
                            />
                        </RadiusBox>
                    </>
                )}
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        allPage(
            filter: {
                type: { in: ["client-consent-template-form", "client-legal-consent-templates"] }
            }
        ) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
    }
`;

export default ClientLegalConsentTemplatePreview;
