// extracted by mini-css-extract-plugin
export var backgroundAnimation = "client-legal-consent-template-preview-module--backgroundAnimation--725c5";
export var buttons = "client-legal-consent-template-preview-module--buttons--37496";
export var container = "client-legal-consent-template-preview-module--container--0043c";
export var divider = "client-legal-consent-template-preview-module--divider--03941";
export var grid = "client-legal-consent-template-preview-module--grid--ce04f";
export var opacity = "client-legal-consent-template-preview-module--opacity--d61f6";
export var radiusBox = "client-legal-consent-template-preview-module--radius-box--82bb5";
export var sectionBox = "client-legal-consent-template-preview-module--section-box--59645";
export var templateTitle = "client-legal-consent-template-preview-module--template-title--a59af";
export var topWrapper = "client-legal-consent-template-preview-module--top-wrapper--91004";
export var wrapper = "client-legal-consent-template-preview-module--wrapper--b52f0";